import React, { useState } from "react";
import ErrorField from "../common/ErrorField";
import { validateEmail, isEmpty } from "../../functions";
import { Helmet } from "react-helmet";
import { siteKey } from "../../config";

const Recaptcha = React.lazy(() => import("react-recaptcha"));
const isSSR = typeof window === "undefined";
const ContactForm = ({ actionURL }) => {
  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectErr, setSubjectErr] = useState("");
  const [message, setMessage] = useState("");
  const [messageErr, setMessageErr] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [captchaErr, setCaptchaErr] = useState("");

  const validateForm = () => {
    let isValid = true;
    if (!isEmpty(firstName)) {
      setFirstNameErr("Name cannot be empty!");
      isValid = false;
    }
    if (!isEmpty(email)) {
      setEmailErr("Email cannot be empty!");
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailErr("Enter valid email!");
      isValid = false;
    }
    if (!isEmpty(subject)) {
      setSubjectErr("Subject cannot be empty!");
      isValid = false;
    }
    if (!isEmpty(message)) {
      setMessageErr("Message cannot be empty!");
      isValid = false;
    }
    if (!isVerified) {
      setCaptchaErr("Please check the box!");
      isValid = false;
    }
    return isValid;
  };

  const onSubmit = (event) => {
    const isValid = validateForm();
    if (!isValid) {
      event.preventDefault();
    }
  };

  const verifyCallback = () => {
    setVerified(true);
  };

  const onloadCallback = () => {
    setVerified(false);
  };

  return (
    <>
      <form
        action="https://crm.zoho.com/crm/WebToContactForm"
        name="WebToContacts4858011000000335002"
        method="POST"
        className="tagmanager-form-contactus form form-contact bg-white"
        data-response-message-animation="slide-in-up"
        acceptCharset="UTF-8"
        encType="multipart/form-data"
        id="form"
        onSubmit={onSubmit}
        style={{ boxShadow: "0 0 13px 0px #bbb" }}
      >
        <input
          type="text"
          className="d-none"
          aria-label="xnQsjsdp"
          name="xnQsjsdp"
          value="b24dadb1ff593701075994ef3f7d4d6554950de515979818983076aaaffcc71f"
          readOnly
        ></input>
        <input
          type="hidden"
          name="zc_gad"
          id="zc_gad"
          value=""
          readOnly
        ></input>
        <input
          type="text"
          className="d-none"
          aria-label="xmIwtLD"
          name="xmIwtLD"
          value="ef65226f2095195355f4a03d8fdd4db1b1e5896204a678044bf0a1dfe62f5833"
          readOnly
        ></input>
        <input
          type="text"
          className="d-none"
          aria-label="actionType"
          name="actionType"
          value="Q29udGFjdHM="
          readOnly
        ></input>
        <input
          type="text"
          className="d-none"
          aria-label="returnURL"
          name="returnURL"
          defaultValue="https&#x3a;&#x2f;&#x2f;www.grootan.com&#x2f;contactus-thank-you"
          readOnly
        ></input>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="bold" htmlFor="First_Name">
                First Name
              </label>
              <input
                id="First_Name"
                type="text"
                maxLength="40"
                name="First Name"
                fieldtype="7"
                className="form-control bg-contrast shadow-none"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                required
              />
              <ErrorField errMessage={firstNameErr} />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="bold" htmlFor="Last_Name">
                Last Name
              </label>{" "}
              <input
                id="Last_Name"
                type="text"
                maxLength="40"
                name="Last Name"
                fieldtype="7"
                className="form-control bg-contrast shadow-none"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="bold" htmlFor="Email">
            Email
          </label>{" "}
          <input
            ftype="email"
            id="Email"
            name="Email"
            maxLength="200"
            type="text"
            checktype="c5"
            className="form-control bg-contrast shadow-none"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
          />
          <small
            id="emailHelp"
            className="form-text text-secondary mt-0 mb-2 italic"
          >
            We'll never share your email with anyone else.
          </small>
          <ErrorField errMessage={emailErr} />
        </div>
        <div className="form-group">
          <label className="bold" htmlFor="Phone">
            Phone No
          </label>
          <input
            id="Phone"
            name="Phone"
            maxLength="40"
            type="tel"
            compname="PhoneNumber"
            checktype="c7"
            phoneformat="1"
            iscountrycodeenabled="false"
            fieldtype="11"
            valtype="number"
            phoneformatyype="2"
            className="form-control bg-contrast shadow-none"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
            pattern="^\+(?:[0-9] ?){6,14}[0-9]$"
          />
          <small
            id="phoneHelp"
            className="form-text text-secondary mt-0 mb-2 italic"
          >
            Please provide phone number with country code.
          </small>
        </div>
        <div className="form-group">
          <label className="bold" htmlFor="Department">
            Subject
          </label>{" "}
          <input
            id="Department"
            name="Department"
            maxLength="50"
            type="text"
            checktype="c1"
            fieldtype="1"
            className="form-control bg-contrast shadow-none"
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
            required
          />
          <ErrorField errMessage={subjectErr} />
        </div>
        <div className="form-group">
          <label className="bold" htmlFor="Description">
            Message
          </label>{" "}
          <textarea
            id="Description"
            name="Description"
            checktype="c1"
            maxLength="65535"
            className="form-control bg-contrast resize-none shadow-none"
            rows={2}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            required
          />
          <ErrorField errMessage={messageErr} />
        </div>
        {!isSSR && (<React.Suspense fallback={<div />}>
          <div className="form-group my-4 pb-3">
            <Recaptcha
              sitekey={siteKey}
              render="explicit"
              onloadCallback={onloadCallback}
              verifyCallback={verifyCallback}
            />
            <ErrorField errMessage={captchaErr} />
          </div>
        </React.Suspense>)}
        <div className="form-group">
          <button
            title="Submit"
            id="formsubmit"
            data-loading-text="Sending..."
            name="submit"
            type="submit"
            className="tagmanager-submit-contactus btn btn-primary btn-rounded btn-lg"
          >
            Send Message
          </button>
        </div>
      </form>
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
          async
          defer
        ></script>
      </Helmet>
    </>
  );
};

export default ContactForm;
