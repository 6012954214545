import React from "react";
import Layout from "../components/layout";
import Contactus from "../components/views/contactus";

const Contact = () => {
  return (
    <Layout>
      <Contactus />
    </Layout>
  );
};

export default Contact;
